import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import axiosClient from "../utils/axiosClient";
import { UserContext } from "../auth/UserContext";
import { AddToCartContext, useAddToCart } from "../Context/AddToCartContext";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { cartState, setCartState } = useContext(AddToCartContext);
  const [cart, setCart] = useState([]);
  const [designCart, setDesignCart] = useState([]);
  const [customCart, setCustomCart] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const { cartCount } = useAddToCart();
  const [selectedPaymentMethod, setselectedPaymentMethod] = useState(null);

  // const [cartState, setCartState] = useState({ cart, designCart, customCart });
  const [loading, setLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [newAddress, setNewAddress] = useState({
    address: "",
    city: "",
    postal_code: "",
  });
  const [cities, setCities] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [deliveryCharges, setDeliveryCharges] = useState(220);
  const [showCODModal, setShowCODModal] = useState(false);
  const [orderCODConfirmModal, setOrderCODConfirmModal] = useState(false);
  const { removeFromCart, resetCart } = useAddToCart();
  const [promoCode, setPromoCode] = useState("");
  const [promoMessage, setPromoMessage] = useState("");
  const [promoSuccessMessage, setPromoSuccessMessage] = useState("");
  const [validPromo, setValidPromo] = useState(null);
  const [promoCodes, setPromoCodes] = useState([]);

  const [codUserOrderDetails, setCodUserOrderDetails] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email: user?.email || "",
    phone_number: user?.phone_number || "",
  });
  const [generalError, setGeneralError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [shippingForm, setShippingForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    city: "",
    postal_code: "",
    phone:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingForm({ ...shippingForm, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const togglePayment = (index) => {
    setselectedPaymentMethod(selectedPaymentMethod === index ? null : index);
    setGeneralError("");
  };

  const fetchCart = async () => {
    try {
      const productCartResponse = await axiosClient.get(`/api/cart`, {
        withCredentials: true,
      });
      setCart(productCartResponse.data.cart || []);

      const designCartResponse = await axiosClient.get(`/api/design_cart`, {
        withCredentials: true,
      });
      setMessage(designCartResponse.data.message);
      setDesignCart(designCartResponse.data.cart || []);

      const customCartResponse = await axiosClient.get(`/api/custom_cart`, {
        withCredentials: true,
      });
      setCustomCart(customCartResponse.data.cart || []);
      setCartState({
        cart: productCartResponse.data.cart || [],
        designCart: designCartResponse.data.cart || [],
        customCart: customCartResponse.data.cart || [],
      });
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else if (error.request) {
        setError("No response from the server.");
      } else {
        setError("Error setting up the request.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchCart();
  }, []);

  useEffect(() => {
    // fetchCart();
  }, [cartCount]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCodUserOrderDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    // fetchAddresses();
    fetchCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAddresses = async () => {
    try {
      const response = await axiosClient.get(`/api/address`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setAddresses(response.data.addresses);
      }
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    }
  };

  const fetchCities = async () => {
    try {
      const response = await axiosClient.get(`/api/city`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setCities(response.data.cities);
      }
    } catch (error) {
      console.error("Failed to fetch cities:", error);
    }
  };

  const fetchPromoCodes = async () => {
    try {
      const response = await axiosClient.get("/api/promo/");
      if (response.status === 200) {
        setPromoCodes(response.data.Promos);
      }
    } catch (error) {
      console.error("Failed to fetch promo codes:", error);
    }
  };

  const validatePromoCode = () => {
    if (!promoCode) {
      setPromoMessage("Please enter a promo code.");
      setPromoSuccessMessage("");
      setValidPromo(null);
      return;
    }
    const promo = promoCodes.find(
      (p) => p.code && p.code.toLowerCase() === promoCode.toLowerCase()
    );

    if (!promo) {
      setPromoSuccessMessage("");
      setPromoMessage("Invalid promo code.");
      setValidPromo(null);
      return;
    }

    const today = new Date();
    const expiryDate = new Date(promo.expiry);

    if (expiryDate < today) {
      setPromoSuccessMessage("");
      setPromoMessage("Promo code has expired.");
      setValidPromo(null);
      return;
    }

    if (promo.uses <= 0) {
      setPromoSuccessMessage("");
      setPromoMessage("Promo code usage limit has been reached.");
      setValidPromo(null);
      return;
    }

    setPromoMessage("");
    setPromoSuccessMessage("Promo code applied successfully!");
    setValidPromo(promo);
  };

  useEffect(() => {
    fetchPromoCodes();
    // fetchAddresses();
  }, []);

  useEffect(() => {
    calculateDiscountedTotal();
  }, [validPromo]);

  const processOrder = async (url) => {
    setLoading(true);
    try {
      const response = await axiosClient.post(
        url,
        {
          cartState,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setOrderCODConfirmModal(true);
        setMessage(
          "Order placed! You will receive an email confirmation shortly!"
        );
        setCartState({
          cart: [],
          designCart: [],
          customCart: [],
        });
      } else {
        console.log("email error not send");
        setMessage("An error occurred. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      console.log("error accoured");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validateFields = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation

    if (!shippingForm.first_name.trim())
      errors.first_name = "First name is required.";
    if (!shippingForm.last_name.trim())
      errors.last_name = "Last name is required.";
    if (!shippingForm.email.trim()) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(shippingForm.email)) {
      errors.email = "Invalid email format.";
    }
    if (!shippingForm.address.trim()) errors.address = "Address is required.";
    if (!shippingForm.city.trim()) errors.city = "City is required.";
    if (!shippingForm.postal_code.trim()) {
      errors.postal_code = "Postal code is required.";
    } else if (isNaN(shippingForm.postal_code)) {
      errors.postal_code = "Postal code must be a number.";
    }

    if (!selectedPaymentMethod) {
      setGeneralError("Please select a payment method.");
    } else {
      setGeneralError(null);
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0 && selectedPaymentMethod !== null;
  };

  const handleCheckout = async () => {
    if (!validateFields()) {
      return;
    }
    setLoading(true);
    // Proceed with checkout logic if validations pass
    setGeneralError(null);

    // if (!selectedAddressId) {
    //   alert("Please select a delivery address");
    //   return;
    // }

    // setCheckoutLoading(true);

    try {
      // Get order ID and access token
      const orderResponse = await axiosClient.post(
        "/api/create_checkout_session/",
        {
          shipping_address: shippingForm,
          payment_method: selectedPaymentMethod,
          promo_code_details: validPromo,
          cartState,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const orderId = orderResponse.data.order_id;
      const userId = orderResponse.data.user_id;
      const totalPrice = orderResponse.data.total_price;
      const accessToken = orderResponse.data.access_token;

      if (selectedPaymentMethod === "card") {
        if (!accessToken) {
          console.error("Failed to retrieve access token");
          setCheckoutLoading(false);
          return;
        } else {
          // Store order details and show modal
          setOrderDetails({ orderId, totalPrice, accessToken, userId });
          setShowConfirmModal(true);
          setCheckoutLoading(false);
          setLoading(false);
        }
      } else {
        setOrderDetails({ orderId, userId, totalPrice, accessToken });
        setCheckoutLoading(false);
        setLoading(false);
        if (orderId) {
          processOrder(`/api/confirm_checkout/${orderId}/${userId}`);
        }
      }
    } catch (error) {
      console.error("Error during checkout process:", error);
    } finally {
      setCheckoutLoading(false);
      setLoading(false);
    }
  };
  const handleCODOrder = (order) => {
    setShowCODModal(false);
  };

  const delProduct = async (id, type) => {
    // removeFromCart();
    // setLoading(true);

    try {
      // const response = await axiosClient.delete(`/api/delete_cart_item/${id}/${type}`, {
      //   method: "DELETE",
      //   credentials: "include", // This includes credentials with the request
      // });

      // if (response.ok) {
      const newCartState = {
        ...cartState,
        [type]: cartState[type].filter((item) => item.id !== id),
      };
      setCartState({ ...newCartState });
      setLoading(false);
      // } else {
      //   console.error("Failed to delete the item:", response.statusText);
      //   setLoading(false);
      // }
    } catch (error) {
      setLoading(false);
      console.error("Error occurred while deleting the item:", error);
    }
  };

  // const getTotal = () => {
  //   let total = 0;
  //   Object.values(cartState).forEach(cartItems => {
  //     cartItems.forEach(item => {
  //       const key = item.product ? 'product' : 'design';
  //       const price = item[key]?.discount_price || item[key]?.product_price;
  //       total += price * item.quantity;
  //     });
  //   });
  //   return total;
  // };

  const getTotal = () => {
    let total = 0;
    Object.values(cartState).forEach((cartItems) => {
      cartItems.forEach((item) => {
        console.log({ item });
        let price = 0;
        const key = item.product ? "product" : "design";
        if (key === "design" && item.shirt_style?.apparel_type === "hoodie") {
          price = item[key]?.discount_price || item[key]?.product_price;
          price = 3199;
        } else {
          if(item.product){

          price = item[key]?.discount_price || item[key]?.product_price;

          }
          else
          {
            price = item[key]?.discount_price || item[key]?.product_price;
            price=2599;

          }
         
        }
        total += price * item.quantity;
      });
    });

    // // Apply promo code discount
    // if (validPromo) {
    //   if (validPromo.type === "percentage") {
    //     total = total - total * (validPromo.discount / 100);
    //   } else if (validPromo.type === "value") {
    //     total = total - validPromo.discount;
    //   }
    // }

    return total > 0 ? total : 0; // Ensure total doesn't go below 0
  };

  const handleAddressChange = (address) => {
    setSelectedAddressId(address.id);
  };

  const itemDisplay = (item, type) => {
    console.log(item)
    const key = type === "cart" ? "product" : "design";
    const priceKey = type === "cart" ? "product_price" : "design_price";
    const discountKey = "discount_price";
    const hasDiscount = item[key]?.[discountKey];
    const price = hasDiscount
      ? item[key]?.[discountKey]
      : item[key]?.[priceKey];
    const originalPrice = item[key]?.[priceKey];
    let imgSrc;
    if (type === "customCart") {
      imgSrc = item.image; // For customCart, use the item.url directly without any prefix
    } else {
      imgSrc = `https://api.dripsaint.com${item[key]?.thumbnail || item[key]?.image || item.image || item.url
        }`;
    }
    return (
      <div className="flex items-center justify-between mb-4 bg-gray-50 p-4 rounded-lg shadow-md">
        {/* Image Section */}
        {/* {JSON.stringify(item)} */}
        <img
          src={imgSrc}
          alt="product-image"
          className="w-16 h-16 object-cover rounded-lg"
        />

        {/* Product Details */}
        {!item.product?(
        <div className="flex-1 ml-4">
          <h2 className="text-sm font-medium text-gray-900">
            {item.product_name || item.design_name}
          </h2>
          <span className="text-xs font-medium">Size: {item.size},</span>
          <span className="text-xs font-medium ml-2">Type: {item.shirt_style.apparel_type
  ? item.shirt_style.apparel_type.charAt(0).toUpperCase() + item.shirt_style.apparel_type.slice(1)
  : ""}</span>
          <p className="text-xs font-medium text-gray-800 mt-1">Style: {item.shirt_style?.style}</p>
          <p className="text-xs font-medium text-gray-800 mt-1">
            {hasDiscount ? (
              <>
                <span className="line-through text-gray-500 mr-1">
                  Rs{" "}
                  {item.shirt_style?.apparel_type === "hoodie"
                    ? 4500
                    : 3999}
                </span>
                Rs{" "}
                {item.shirt_style?.apparel_type === "hoodie"
                  ? 3199
                  : 2599}
              </>
            ) : (
              `Rs ${item.shirt_style?.apparel_type === "hoodie"
                ? 3199
                : 2599
              }`
            )}
          </p>
        </div>):
        (


          <div className="flex-1 ml-4">
          <h2 className="text-sm font-medium text-gray-900">
            {item.product_name || item.design_name}
          </h2>
          <span className="text-xs font-medium">Size: {item.size},</span>
          {/* <span className="text-xs font-medium ml-2">Type: {item.shirt_style.apparel_type
  ? item.shirt_style.apparel_type.charAt(0).toUpperCase() + item.shirt_style.apparel_type.slice(1)
  : ""}</span>
          <p className="text-xs font-medium text-gray-800 mt-1">Style: {item.shirt_style?.style}</p> */}
          <p className="text-xs font-medium text-gray-800 mt-1">
            {hasDiscount ? (
              <>
                <span className="line-through text-gray-500 mr-1">
                  Rs{" "}
                  {item.product.product_price}
                </span>
                Rs{" "}
                {item.product.discount_price}
               
              </>
            ) : (
              `Rs ${item.product.product_price }`
            )}
          </p>
        </div>

        )
  }
        {/* Quantity & Delete Section */}
        <div className="flex items-center space-x-4">
          <div className="flex items-center bg-gray-200 text-gray-900 px-2 py-1 rounded-md text-xs ml-2">
            <span className="font-medium">Qty:</span>
            <span className="ml-1">{item.quantity}</span>
          </div>
          <button
            onClick={() => delProduct(item.id, type)}
            disabled={loading === item.id}
            className="text-red-500 hover:text-red-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleCODModalClose = () => {
    setShowCODModal(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleNewAddressChange = (event) => {
    setNewAddress({ ...newAddress, [event.target.name]: event.target.value });
  };
  const gotoHomePage = () => {
    setOrderCODConfirmModal(false);
    resetCart();
    navigate("/");
  };

  const handleorderCODModalClose = () => {
    setOrderCODConfirmModal(false);
    resetCart();
  };

  const handleAddNewAddress = async () => {
    setLoading("addAddress");
    try {
      const response = await axiosClient.post(`/api/address/`, newAddress, {
        withCredentials: true,
      });
      if (response.status === 200) {
        // fetchAddresses(); // Refresh addresses
        setShowModal(false); // Close modal
        setNewAddress({ address: "", city: "", postal_code: "" }); // Reset form
      }
    } catch (error) {
      console.error("Failed to add new address:", error);
    } finally {
      setLoading(null);
    }
  };

  const calculateDiscountedTotal = () => {
    let total = getTotal() + deliveryCharges;
    console.log(validPromo);

    if (validPromo?.code) {
      if (validPromo.discount_type === "percentage") {
        total -= total * (validPromo.discount / 100);
      } else if (validPromo.discount_type === "value") {
        total -= validPromo.discount;
      }
    }

    return Math.max(Math.round(total), 0);
  };

  return (
    <Layout background="">
      <div className="m-5 bg-gray-100 min-h-screen w-full">
        <div className="container px-4 py-8 bg-white shadow-md">
          {/* Header */}
          <div className="mb-2 text-center">
            <h1 className="text-2xl font-bold text-gray-800">Checkout</h1>
            <p className="text-sm text-gray-500">
              Complete your order details below
            </p>
          </div>

          <div className="flex flex-col items-center justify-center h-full text-black mt-10">
            {loading ? (
              <span className="loading loading-spinner loading-lg"></span>
            ) : error ? (
              <div>{error}</div>
            ) : (
              <>
                {/* {cart?.length === 0 && customCart?.length === 0 && designCart?.length === 0 ? ( */}
                {cartState?.cart?.length === 0 &&
                  cartState?.customCart?.length === 0 &&
                  cartState?.designCart?.length === 0 ? (
                  <div>
                    <h1 className="text-4xl font-extrabold text-black">
                      Nothing here :/
                    </h1>
                    <h1 className="text-2xl text-black">
                      Try adding something to the cart
                    </h1>
                  </div>
                ) : (
                  <>
                    {/* Grid Layout */}
                    <div className="grid md:grid-cols-3 gap-6">
                      {/* Left: Shipping and Payment */}
                      <div className="md:col-span-2">
                        {/* Shipping Information */}
                        <div className="bg-gray-50 p-6 rounded-lg shadow-sm mb-6">
                          <h2 className="text-xl font-bold text-gray-800 mb-4">
                            Shipping Information
                          </h2>
                          <div className="">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                              {/* First Name */}
                              <div>
                                <label className="block text-sm font-medium text-gray-700">
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  value={shippingForm.first_name}
                                  onChange={handleChange}
                                  className="w-full px-3 py-2 border rounded-md focus:outline-none bg-transparent"
                                />
                                {formErrors.first_name && (
                                  <p className="text-sm text-red-500">
                                    {formErrors.first_name}
                                  </p>
                                )}
                              </div>

                              {/* Last Name */}
                              <div>
                                <label className="block text-sm font-medium text-gray-700">
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  name="last_name"
                                  value={shippingForm.last_name}
                                  onChange={handleChange}
                                  className="w-full px-3 py-2 border rounded-md focus:outline-none bg-transparent"
                                />
                                {formErrors.last_name && (
                                  <p className="text-sm text-red-500">
                                    {formErrors.last_name}
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* Email */}
                            <div className="col-span-2 mt-2">
                              <label className="block text-sm font-medium text-gray-700">
                                Email
                              </label>
                              <input
                                type="email"
                                name="email"
                                value={shippingForm.email}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-md focus:outline-none bg-transparent"
                              />
                              {formErrors.email && (
                                <p className="text-sm text-red-500">
                                  {formErrors.email}
                                </p>
                              )}
                            </div>
                            <div className="col-span-2 mt-2">
                              <label className="block text-sm font-medium text-gray-700">
                                Phone
                              </label>
                              <input
                                type="phone"
                                name="phone"
                                value={shippingForm.phone}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-md focus:outline-none bg-transparent"
                              />
                              {formErrors.phone && (
                                <p className="text-sm text-red-500">
                                  {formErrors.phone}
                                </p>
                              )}
                            </div>

                            {/* Address */}
                            <div className="col-span-2 mt-2">
                              <label className="block text-sm font-medium text-gray-700">
                                Address
                              </label>
                              <input
                                type="text"
                                name="address"
                                value={shippingForm.address}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-md focus:outline-none bg-transparent"
                              />
                              {formErrors.address && (
                                <p className="text-sm text-red-500">
                                  {formErrors.address}
                                </p>
                              )}
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">

                              {/* City */}
                              <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">
                                  City
                                </label>
                                <select
                                  name="city"
                                  // value={shippingForm.city}
                                  onChange={handleChange}
                                  className="w-full px-3 py-2 border h-[42px] rounded-md focus:outline-none bg-transparent"
                                >
                                  <option value="">Select a city</option>
                                  {cities
                                    .sort((a, b) => a.city.localeCompare(b.city))
                                    .map((city) => (
                                      <option key={city.id} value={city.city}>
                                        {city.city}
                                      </option>
                                    ))}
                                </select>
                                {formErrors.city && (
                                  <p className="text-sm text-red-500">
                                    {formErrors.city}
                                  </p>
                                )}
                              </div>

                              {/* Postal Code */}
                              <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">
                                  Postal Code
                                </label>
                                <input
                                  type="text"
                                  name="postal_code"
                                  value={shippingForm.postal_code}
                                  onChange={handleChange}
                                  className="w-full px-3 py-2 border rounded-md focus:outline-none bg-transparent"
                                />
                                {formErrors.postal_code && (
                                  <p className="text-sm text-red-500">
                                    {formErrors.postal_code}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 ">
                          <h2 className="text-xl font-bold text-gray-800 mb-4">
                            Payment Method
                          </h2>
                          {generalError && (
                            <p className="text-sm text-red-500 mb-4">
                              {generalError}
                            </p>
                          )}
                          <div className="border border-gray-200 rounded-md ">
                            <div className="border-b border-gray-200">
                              <div
                                className="flex justify-between items-center px-4 py-3 cursor-pointer border border-b border-gray-200"
                                onClick={() => togglePayment("cod")}
                              >
                                <label className="flex items-center space-x-3 cursor-pointer">
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    checked={selectedPaymentMethod === "cod"}
                                    onChange={() => togglePayment("cod")}
                                    className="form-radio h-5 w-5 text-blue-600 focus:ring-blue-500 bg-transparent"
                                  />
                                  <span className="text-lg font-medium">
                                    Cash On Delivery
                                  </span>
                                </label>
                              </div>
                              <div
                                className={`overflow-hidden transition-all duration-300 ${selectedPaymentMethod === "cod"
                                  ? "max-h-screen py-2"
                                  : "max-h-0"
                                  }`}
                              >
                                <div className="px-4 text-gray-700"></div>
                              </div>

                              <div>
                                <div
                                  className="flex justify-between items-center px-4 py-3 cursor-pointer"
                                  onClick={() => togglePayment("card")}
                                >
                                  <label className="flex items-center space-x-3 cursor-pointer">
                                    <input
                                      type="radio"
                                      name="paymentMethod"
                                      checked={selectedPaymentMethod === "card"}
                                      onChange={() => togglePayment("card")}
                                      className="form-radio h-5 w-5 text-blue-600 focus:ring-blue-500 bg-transparent"
                                    />
                                    <span className="text-lg font-medium">
                                      Pay Online
                                    </span>
                                  </label>
                                </div>
                                <div
                                  className={`overflow-hidden transition-all duration-300 ${selectedPaymentMethod === "card"
                                    ? "max-h-screen py-2"
                                    : "max-h-0"
                                    }`}
                                >
                                  <div className="px-4 text-gray-700">
                                    {/* <p>
                        Select this option to pay using your preferred digital payment
                        method. Secure and instant processing.
                      </p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Right: Order Summary */}
                      <div>
                        <div className="bg-gray-50 p-6 rounded-lg shadow-sm">
                          {["cart", "designCart", "customCart"].map((type) =>
                            cartState[type].map((item) =>
                              itemDisplay(item, type)
                            )
                          )}

                          <h2 className="text-lg font-semibold text-gray-800 mb-4">
                            Order Summary
                          </h2>
                          <div className="space-y-4">
                            <SummaryItem label="Subtotal" value={getTotal()} />
                            <SummaryItem
                              label="Standard Shipping"
                              value={deliveryCharges}
                            />
                            <SummaryItem
                              label="Total"
                              value={calculateDiscountedTotal()}
                              bold
                            />
                          </div>

                          <div className="mt-4 mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700">
                              Promo Code
                            </label>
                            <div className="flex items-center space-x-2">
                              <input
                                type="text"
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}
                                className="w-[70%] px-3 py-2 text-sm border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white"
                                placeholder="Enter Promo Code"
                              />
                              <button
                                className="w-[30%] px-4 py-2 rounded-md bg-blue-500 font-medium text-white hover:bg-blue-600"
                                onClick={validatePromoCode}
                              >
                                Apply
                              </button>
                            </div>
                            {promoMessage && (
                              <p className="mt-2 text-sm font-medium text-red-500">
                                {promoMessage}
                              </p>
                            )}
                            {promoSuccessMessage && (
                              <p className="mt-2 text-sm font-medium text-green-500">
                                {promoSuccessMessage}
                              </p>
                            )}
                          </div>

                          <button
                            onClick={handleCheckout}
                            disabled={loading}
                            className="mt-6 w-full bg-indigo-600 text-white py-3 rounded-md shadow-sm hover:bg-indigo-700"
                          >
                           {loading?'Please wait....':'Place Order'} 
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-xl mb-4">Add New Address</h2>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                name="address"
                value={newAddress.address}
                onChange={handleNewAddressChange}
                className="w-full px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white text-gray-800"
                placeholder="Enter your address"
              />
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                City
              </label>
              <select
                name="city"
                value={newAddress.city}
                onChange={handleNewAddressChange}
                className="w-full px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white text-gray-800"
              >
                <option value="">Select a city</option>
                {cities
                  .sort((a, b) => a.city.localeCompare(b.city))
                  .map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Postal Code
              </label>
              <input
                type="text"
                name="postal_code"
                value={newAddress.postal_code}
                onChange={handleNewAddressChange}
                className="w-full px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent bg-white text-gray-800"
                placeholder="Enter your postal code"
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                onClick={handleModalClose}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                onClick={handleAddNewAddress}
                disabled={loading === "addAddress"}
              >
                {loading === "addAddress" ? "Adding..." : "Add Address"}
              </button>
            </div>
          </div>
        </div>
      )}
      {showCODModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center w-full">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-xl mb-4">COD Order Details</h2>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                name="first_name"
                value={codUserOrderDetails?.first_name}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter your first name"
              />
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                name="last_name"
                value={codUserOrderDetails?.last_name}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter your last name"
              />
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={codUserOrderDetails?.email}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter your email"
              />
            </div>
            <div className="mt-4">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone_number"
                value={codUserOrderDetails?.phone_number}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter your phone number"
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                onClick={handleCODModalClose}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                onClick={() =>
                  handleCODOrder({
                    ...codUserOrderDetails,
                    selectedAddress,
                    cartState,
                  })
                }
              >
                Confirm order
              </button>
            </div>
          </div>
        </div>
      )}

      {orderCODConfirmModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-xl mb-4">Order Confirmation</h2>
            <p className="text-center">{message}</p>

            <div className="mt-4 flex justify-end">
              <button
                type="button"
                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                onClick={() => handleorderCODModalClose(false)}
              >
                Cancel
              </button>
              <button
                onClick={() => gotoHomePage()}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Home
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-xl mb-4">Confirm Your Purchase</h2>
            <form
              action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction"
              method="POST"
              onSubmit={() => setCheckoutLoading(true)}
            >
              {/* Hidden Fields */}
              <input type="hidden" name="CURRENCY_CODE" value="PKR" />
              <input type="hidden" name="MERCHANT_ID" value="22926" />
              <input type="hidden" name="MERCHANT_NAME" value="Dripsaint" />
              <input
                type="hidden"
                name="TOKEN"
                value={orderDetails.accessToken}
              />
              <input
                type="hidden"
                name="TXNAMT"
                value={orderDetails.totalPrice}
              />
              <input
                type="hidden"
                name="CUSTOMER_MOBILE_NO"
                value={shippingForm.phone}
              />
              <input
                type="hidden"
                name="CUSTOMER_EMAIL_ADDRESS"
                value={shippingForm.email}
              />
              <input
                type="hidden"
                name="CHECKOUT_URL"
                value="https://api.dripsaint.com/api/confirm_transaction/"
              />
              <input
                type="hidden"
                name="SUCCESS_URL"
                value={`https://www.dripsaint.com/checkout?success=true&order_id=${orderDetails.orderId}&user_id=${orderDetails.userId}`}
              />
              <input
                type="hidden"
                name="FAILURE_URL"
                value={`https://www.dripsaint.com/checkout?cancel=true&order_id=${orderDetails.orderId}&user_id=${orderDetails.userId}`}
              />
              <input
                type="hidden"
                name="BASKET_ID"
                value={orderDetails.orderId}
              />
              <input type="hidden" name="TRAN_TYPE" value="ECOMM_PURCHASE" />
              <input
                type="hidden"
                name="ORDER_DATE"
                value={new Date().toISOString()}
              />
              <input type="hidden" name="SIGNATURE" value="" />{" "}
              {/* Add the signature if needed */}
              <input type="hidden" name="VERSION" value="MERCHANT_CART-0.1" />
              <input
                type="hidden"
                name="TXNDESC"
                value="Item Purchased from Cart"
              />
              <input type="hidden" name="PROCCODE" value="00" />
              <input type="hidden" name="STORE_ID" value="" />{" "}
              {/* Optional Store ID */}
              <input type="hidden" name="Recurring_Transaction" value="true" />
              <input
                type="hidden"
                name="MERCHANT_USERAGENT"
                value="Mozilla/5.0"
              />
              {/* Cart Items */}
              {cartState.cart.map((item, index) => (
                <div key={`cart-item-${index}`}>
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][SKU]`}
                    value={`product-${item.product.id}`}
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][NAME]`}
                    value={item.product.name}
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][PRICE]`}
                    value={item.product.price}
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][QTY]`}
                    value={item.quantity}
                  />
                </div>
              ))}
              {cartState.designCart.map((item, index) => (
                <div key={`design-item-${index}`}>
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][SKU]`}
                    value={`design-${item.design.id}`}
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][NAME]`}
                    value={item.design.name}
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][PRICE]`}
                    value={item.design.price}
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][QTY]`}
                    value={item.quantity}
                  />
                </div>
              ))}
              {cartState.customCart.map((item, index) => (
                <div key={`custom-item-${index}`}>
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][SKU]`}
                    value={`custom-shirt`}
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][NAME]`}
                    value="Ai Shirt"
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][PRICE]`}
                    value="3500"
                  />
                  <input
                    type="hidden"
                    name={`ITEMS[${index}][QTY]`}
                    value={item.quantity}
                  />
                </div>
              ))}
              <p>Are you sure you want to proceed with the transaction?</p>
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                  onClick={() => setShowConfirmModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                  disabled={checkoutLoading}
                >
                  {checkoutLoading ? "Processing..." : "OK"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
};

const InputField = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  colSpan,
}) => (
  <div className={colSpan || ""}>
    <label htmlFor={name} className="block text-sm font-medium text-gray-800">
      {label} <stong className="text-red-700">*</stong>
    </label>
    <input
      type={type}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      className="mt-1 p-2 block w-full rounded-md bg-transparent border border-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
    />
  </div>
);

const SummaryItem = ({ label, value, bold = false }) => (
  <div className={`flex justify-between text-sm ${bold ? "font-bold" : ""}`}>
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

export default CheckoutPage;
