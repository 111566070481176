import React from 'react'
import { DotButton, useDotButton } from './carousel/EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './carousel/EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'

const HowItWorks = (props) => {
  const {  options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)


  const images = [
    "/dripSaintAssets/How-it-Works-1.webp",
    "/dripSaintAssets/How-it-Works-2.webp",
    "/dripSaintAssets/How-it-Works-3.webp",
  ];


  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
           {images.map((image, index) => (
            <div
              key={index}
              className="embla__slide px-2 pb-8 mx-auto flex-shrink-0 max-w-full h-auto"
            >
              <img
                src={image}
                alt={`How it Works ${index + 1}`}
                style={{ width: "100%", maxWidth: "400px", height: "auto" }}
              />
            </div>
          ))}
        </div>
      </div>
<center>
      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div>
      </center>
    </section>
  )
}

export default HowItWorks
