import React, { useEffect, useState } from 'react'
import { DotButton, useDotButton } from './carousel/EmblaCarouselDotButton'
// import { useNavigate } from "react-router-dom"; 
//import './Hotpick.css'; // Import CSS for styling

import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './carousel/EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'
import axios from 'axios'

const HotPicks = (props) => {
  const { options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const [designs, setDesigns] = useState([]);
  // const navigate = useNavigate(); 
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  useEffect(() => {
    // Fetch designs from the backend
    axios
      .get("https://api.dripsaint.com/api/get_design_picks/")
      .then((response) => {
        setDesigns(response.data.designs.slice(0, 8)); // Select first 4 designs
      })
      .catch((error) => {
        console.error("There was an error fetching the designs!", error);
      });
  }, []);

  // Handle the Buy button click
  const handleBuy = (id) => {
    window.location.href = `/generated/no/${id}`;
    // navigate(`/generated/no/${id}`, { replace: true });
  };

  return (
    <section className="embla">
      <h1 className="text-4xl sm:text-6xl font-abril text-black text-center py-8">{props.title}</h1>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container grid grid-cols-1 sm:grid-cols-2 gap-4 px-4">
          {designs.map((design) => (
            <div
              key={design.id}
              className="embla__slide flex-shrink-0 max-w-full h-auto"
              onClick={() => handleBuy(design.id)}
            >
              <div className="relative mx-auto text-white-700 rounded-xl">
                <img
                  src={`https://api.dripsaint.com${design.image}`}
                  alt={design.design_name}
                  className="w-full"
                />
              </div>
              <div className="px-4 mt-3">
                <div className="flex items-center justify-between mb-2">
                  <p className="block font-sans text-base font-bold text-black">
                    {design.design_name}
                  </p>
                </div>
                <p className="block font-sans text-base font-bold text-black">
                  PKR 3199
                </p>
                <p className="block font-sans text-sm text-black opacity-75">
                  {design.design_detail}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <center>
        <div className="embla__controls ml-3">
          <div className="embla__buttons">
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </div>
          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={'embla__dot'.concat(
                  index === selectedIndex ? ' embla__dot--selected' : ''
                )}
              />
            ))}
          </div>
        </div>
      </center>
    </section>
  )
}

export default HotPicks
