import React, { useContext, useState } from "react";
import { AddToCartContext } from "../Context/AddToCartContext";
import axiosClient from "../utils/axiosClient";
import { useNavigate } from "react-router-dom";

const CartDrawer = () => {

  const {cartOpen, setCartOpen, cartLoading, cartState, setCartState} = useContext(AddToCartContext) 
  const navigate = useNavigate();
  const delProduct = async (id, type) => {
    // removeFromCart();

    try {
      // const response = await axiosClient.delete(`/api/delete_cart_item/${id}/${type}`, {
      //   method: "DELETE",
      //   credentials: "include", // This includes credentials with the request
      // });

      // if (response.ok) {
        const newCartState = {
          ...cartState,
          [type]: cartState[type].filter((item) => item.id !== id),
        };
        setCartState({...newCartState});
    //   } else {
    //     console.error("Failed to delete the item:", response.statusText);
    //   }

    //   getCartDetails()
    } catch (error) {
      console.error("Error occurred while deleting the item:", error);
    }
  };

  const itemDisplay = (item, type) => {
    // if(type=='cart'){
    console.log('hello',item);
    console.log(type);
    const key = type === "cart" ? "product" : "design";
    const priceKey = type === "cart" ? "product_price" : "design_price";
    const discountKey = "discount_price";
    const hasDiscount = item[key]?.[discountKey];
    const price = hasDiscount ? item[key]?.[discountKey] : item[key]?.[priceKey];
    const originalPrice = item[key]?.[priceKey];
    const pprice=item[key]?.["product_price"];
    const dprice=item[key]?.["discount_price"];
    if(type==='cart')
      {

console.log(key,priceKey,discountKey,hasDiscount,price,originalPrice);
console.log(pprice,dprice);


      }
    let imgSrc;
    if (type === "customCart") {
      imgSrc = item.image; // For customCart, use the item.url directly without any prefix
    } else {
      imgSrc = `https://api.dripsaint.com${item[key]?.thumbnail || item[key]?.image || item.image || item.url}`;
    }
    return (
      <div className="flex items-center justify-between mb-4 bg-gray-50 p-4 rounded-lg shadow-md">
        {/* Image Section */}
        <img src={imgSrc} alt="product-image" className="w-16 h-16 object-cover rounded-lg" />

        {/* Product Details */}
        <div className="flex-1 ml-4">
          <h2 className="text-sm font-medium text-gray-900">{item.product_name || item.design_name}</h2>
          <p className="mt-1 text-xs text-gray-600 ">
            <span className="font-bold">Size:</span> {item.size}, 
            </p>
            <p className="text-xs font-medium text-gray-800 mt-1">{item.shirt_style?.style}</p>
          <p className="text-xs font-medium text-gray-800 mt-1">
            {item.design==="Product"&&
            (hasDiscount ? (
              <>
                <span className="line-through text-gray-500 mr-1">Rs {originalPrice}</span>
                Rs {price}
              </>
            ) : (
              `Rs ${originalPrice}`
            ))}

{item.design!=="Product"&&
            (hasDiscount ? (
              <>
                <span className="line-through text-gray-500 mr-1">Rs {item.product_price === 'hoodie' ? 4500 : originalPrice}</span>
                Rs {item.shirt_style?.apparel_type === 'hoodie' ? 3199 : price}
              </>
            ) : (
              `Rs ${item.shirt_style?.apparel_type === 'hoodie' ? 3199 : price}`
            ))}

          </p>
        </div>

        {/* Quantity & Delete Section */}
        <div className="flex items-center space-x-4">
          <div className="flex items-center bg-gray-200 text-gray-900 px-2 py-1 rounded-md text-xs ml-2">
            <span className="font-medium">Qty:</span>
            <span className="ml-1">{item.quantity}</span>
          </div>
          <button
            onClick={() => delProduct(item.id, type)}
            className="text-red-500 hover:text-red-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  const getTotal = () => {
    let total = 0;
    Object.values(cartState).forEach((cartItems) => {
      cartItems.forEach((item) => {
        console.log({ item });
        let price = 0
        const key = item.product ? "product" : "design";
        if (key === 'design' && item.shirt_style?.apparel_type === 'hoodie') {
          price = (item[key]?.discount_price || item[key]?.product_price);
          price = 3199
        } else {
          price = item[key]?.discount_price || item[key]?.product_price;
        }
        total += price * item.quantity;
      });
    });

    return total > 0 ? total : 0; 
  };


  return (
    <>

      <div
        className={`fixed top-0 right-0 h-full w-[90%] md:w-[50%] lg:w-[40%] xl:w-[25%] bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-40 ${
          cartOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Close Button */}
        <div className="text-xl p-4 mt-10 border-b flex justify-between items-center">
          <h2 className=" text-black font-semibold">Shopping Cart</h2>
          <button
            onClick={() => setCartOpen(false)}
            className="text-gray-500 hover:text-black"
          >
            &times;
          </button>
        </div>
        {/* {JSON.stringify(cartState)} */}

        {!cartLoading ? (
      <>
        {/* Cart Content */}
        <div className="p-4 space-y-4">
          {["cart", "designCart", "customCart"].map((type) =>
            cartState[type].map((item) => itemDisplay(item, type)),
          )}
        </div>
      </>
      ):
      <></>}
        {/* Cart Footer */}
        <div className="absolute bottom-0 w-full p-4 bg-gray-50 border-t">
          <div className="flex justify-between text-lg font-semibold">
            <span>Subtotal</span>
            <span>Rs {getTotal()}</span>
          </div>
          <button onClick={()=> {navigate('/cart'); setCartOpen(false)}} className="w-full bg-black text-white py-2 mt-4 hover:bg-gray-800">
            Check Out
          </button>
        </div>
      </div>
  
      

      {/* Overlay to dim background */}
      {cartOpen && (
        <div
          onClick={() => setCartOpen(false)}
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
        ></div>
      )}
    </>
  );
};

export default CartDrawer;
