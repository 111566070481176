import React, { useState, useEffect, useContext } from "react"; // Import useEffect from React
import { useNavigate } from "react-router-dom";
import DiscountPopup from "./DiscountPopup"; // Import the DiscountPopup component
import HotPicks from "./Hotpicks";
import { AiGenerationsContext } from "../Context/AiGenerationsContext";
import HowItWorks from "./HowItworks";

const Landing = () => {
  const { counter, handleButtonClick, showPopup } =
    useContext(AiGenerationsContext);
  const [inputValue, setInputValue] = useState("");
  const user = localStorage.getItem('token');
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for loading

  const OPTIONS = { slidesToScroll: 'auto' }
 
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const imagineApiImageGeneration = async () => {
    handleButtonClick();

    // If the popup is open or counter exceeds limit, do not proceed with API call
    if (showPopup || (!user && counter >= 3) || (user && counter >= 6)) return;

    setIsLoading(true);
    try {
      const response = await fetch("/api/items/images/", {
        method: "POST",
        headers: {
          Authorization: "Bearer 407153e4f4314885c2306a057388e5ba37ca22e1bf9dfdef03dcab25d2c22b8d6afb16e649e92bb2e525b9e6935bae743f7980cc",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: inputValue + " --ar 4:5" }),
      });
      const responseData = await response.json();
      setData(responseData.data);

      if (responseData.data.id) {
        navigate(`/generated/yes/${responseData.data.id}`);
      }
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
      setIsLoading(false);
      setInputValue("");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleSubmit = () => {
    if (!firstName || !lastName || !email || !message) {
      setError("All fields are required.");
      return;
    }
    // Clear the fields and error message
    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
    setError("");

    // Add your submit logic here, e.g., send data to the server
  };

  return (
    <div className="text-sm h-full pt-22 px-4 sm:px-16 md:px-28 lg:px-32">
      <DiscountPopup /> {/* Added DiscountPopup component here */}
      <section className="flex flex-col md:flex-row justify-between items-center">
        <div
          className={`p-4 flex-shrink flex-grow md:w-1/2 ${
            isMobile ? "text-center" : ""
          }`}
        >
          <h1 className="text-6xl sm:text-7xl md:text-8xl text-black font-abril">
            Custom Fashion
          </h1>
          <p className="mt-4 text-[#000000] text-2xl sm:text-3xl font-economica">
            Enter a one liner AI prompt down below
            <br />
            to have it displayed on your T-Shirt
          </p>

          {/* Mobile Layout */}
          {isMobile && (
            <div className="flex flex-col items-center mt-8">
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered w-full max-w-xs border text-black bg-white rounded-[44px] mb-2 h-[50px]"
                value={inputValue}
                onChange={handleChange}
              />
              <button
                className="shadow-[0px_4px_0px_0px_#4B0082] bg-purple-700 text-white font-bold rounded-full py-2 px-6 transition duration-300 ease-in-out mb-2 h-[50px]"
                onMouseEnter={(e) => {
                  e.target.style.color = "white"; // Keep text white on hover
                  e.target.style.background =
                    "linear-gradient(90deg, #6A0DAD, #8A2BE2)"; // Purple gradient on hover
                  e.target.style.boxShadow =
                    "0 12px 20px rgba(138, 43, 226, 0.5)"; // Purple glow shadow on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = ""; // Reset to className-defined text color
                  e.target.style.background = ""; // Reset to className-defined background color
                  e.target.style.boxShadow = ""; // Reset shadow
                }}
                onClick={imagineApiImageGeneration}
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? "Loading..." : "Create"}
              </button>
            </div>
          )}

          {/* Desktop Layout */}
          {!isMobile && (
            <div className="flex items-center mt-8">
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered w-full max-w-xs border text-black bg-white rounded-[44px] mb-2 h-[50px] mr-4"
                value={inputValue}
                onChange={handleChange}
              />
              <button
                className="shadow-[0px_4px_0px_0px_#4B0082] bg-purple-500 text-white font-bold rounded-full py-2 px-6 transition duration-300 ease-in-out mb-2 h-[50px]"
                onMouseEnter={(e) => {
                  e.target.style.color = "white"; // Keep text white on hover
                  e.target.style.background =
                    "linear-gradient(90deg, #6A0DAD, #8A2BE2)"; // Purple gradient on hover
                  e.target.style.boxShadow =
                    "0 12px 20px rgba(138, 43, 226, 0.5)"; // Purple glow shadow on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = ""; // Reset to className-defined text color
                  e.target.style.background = ""; // Reset to className-defined background color
                  e.target.style.boxShadow = ""; // Reset shadow
                }}
                onClick={imagineApiImageGeneration}
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? "Loading..." : "Create"}
              </button>
            </div>
          )}
        </div>

        <div className="hidden lg:flex flex-grow">
          <div className="flex flex-row items-center gap-12">
            <div className="flex flex-col items-center gap-4">
              <div className="marquee-container h-[700px] w-[250px] relative">
                <div className="marquee overflow-hidden h-[700px] w-[250px]">
                  <div className="marquee-up-content flex flex-col items-center animate-verticalMarqueeUp">
                    <img
                      src="/dripSaintAssets/pic2.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                    <img
                      src="/dripSaintAssets/hod1.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                    <img
                      src="/dripSaintAssets/raq2.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                    <img
                      src="/dripSaintAssets/raq6.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                    <img
                      src="/dripSaintAssets/raq1.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                    <img
                      src="/dripSaintAssets/raq2.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                    <img
                      src="/dripSaintAssets/pic2.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                    <img
                      src="/dripSaintAssets/raq1.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                    <img
                      src="/dripSaintAssets/raq2.webp"
                      alt="Img"
                      className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="marquee-container h-[700px] w-[250px] relative">
              <div className="marquee overflow-hidden h-[700px] w-[250px]">
                <div className="marquee-down-content flex flex-col items-center animate-verticalMarqueeDown">
                  <img
                    src="/dripSaintAssets/raq4.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                  <img
                    src="/dripSaintAssets/hod1.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                  <img
                    src="/dripSaintAssets/pic5.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                  <img
                    src="/dripSaintAssets/raq3.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                  <img
                    src="/dripSaintAssets/hood2.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                  <img
                    src="/dripSaintAssets/raq4.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                  <img
                    src="/dripSaintAssets/hod1.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                  <img
                    src="/dripSaintAssets/raq6.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                  <img
                    src="/dripSaintAssets/raq4.webp"
                    alt="Img"
                    className="w-[250px] h-[350px] rounded-lg object-cover mt-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-16 lg:mt-35 relative">
      <HotPicks options={OPTIONS}  title="Hot Picks" />
      {/* <HotPicks /> */}
      </section>
      
      <div
        className="flex items-center justify-center min-h-fit py-5 mt-20 lg:mt-20 lg:mx-8 lg:ml-0 sm:mt-0"
        style={{ marginTop: "2.5rem" }}
      >
        <h1 className="text-4xl sm:text-6xl font-abril text-black text-center">
          How It Works
        </h1>
      </div>
      <section className="mt-16 lg:mt-35 relative">
        {/* <HowItWorks /> */}
        <HowItWorks options={OPTIONS} />
      </section>
      <div
        className="flex items-center justify-center min-h-fit py-5"
        style={{ marginTop: "4rem" }}
      >
        <h1 className="text-6xl font-abril text-black">FEATURES</h1>
      </div>
      <section className="mt-16 pt-25">
        {isMobile ? (
          <div className="flex flex-col items-center mb-16 gap-8">
            <img
              src="/dripSaintAssets/LandingPage1.jpg"
              alt="img"
              className="w-5/6 rounded-[20px]"
            />
            <div className="p-8 bg-transparent rounded-tl-3xl min-w-[343px] shadow-2xl">
              <h1 className="mb-8 text-black font-bold text-3xl">
                Craft your design with prompt
              </h1>
              <p className="mb-4 text-black w-4/5 text-md">
                Unleash the power of AI design with our innovative software!
                Simply type your text and generate countless original design
                options, no design skills required. Effortlessly create unique
                and stylish tees with our cutting-edge AI design and printing
                platform. Enjoy the ease and creativity of AI-infused design,
                all at your fingertips!
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-evenly items-center mb-16 gap-32">
            <div className="p-8 bg-transparent rounded-tl-3xl min-w-[343px] md:w-2/5 shadow-2xl">
              <h1 className="mb-8 text-black font-bold text-4xl">
                Craft your design with prompt
              </h1>
              <p className="mb-4 text-black w-4/5 text-xl">
                Unleash the power of AI design with our innovative software!
                Simply type your text and generate countless original design
                options, no design skills required. Effortlessly create unique
                and stylish tees with our cutting-edge AI design and printing
                platform. Enjoy the ease and creativity of AI-infused design,
                all at your fingertips!
              </p>
            </div>
            <img
              src="/dripSaintAssets/LandingPage1.jpg"
              alt=""
              className="w-5/12 rounded-[20px] hidden md:block"
            />
          </div>
        )}
      </section>
      <section className="mt-28 pt-28">
        {isMobile ? (
          <div className="flex flex-col items-center mb-16 gap-8">
            <img
              src="/dripSaintAssets/LandingPage2.jpg"
              alt=""
              className="w-5/6 rounded-[20px]"
            />
            <div className="p-8 transparent rounded-tr-[100px] min-w-[343px] shadow-2xl">
              <h1 className="mb-8 text-black font-bold text-3xl">
                Personalize Your Design
              </h1>
              <p className="mb-4 text-black w-4/5 text-md">
                Transform your vision into reality with ease! Our user-friendly
                platform lets you personalize your apparel in just a few clicks.
                Enjoy the flexibility to:
              </p>
              <ul className="mb-3 text-black w-4/5 text-md list-disc list-inside">
                <li>Experiment with multiple design options</li>
                <li>Craft your one-of-a-kind design with Drip Saint</li>
              </ul>
              <p className="text-black w-4/5 text-md">
                When you're satisfied, simply checkout and complete your order.
                Our seamless personalization process makes it easy to bring your
                design to life and make it shine.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-evenly items-center mb-16 gap-32">
            <div className="order-2 p-8 transparent rounded-tr-[100px] min-w-[343px] md:w-1/2 shadow-2xl">
              <h1 className="mb-8 text-black font-bold text-4xl">
                Personalize Your Design
              </h1>
              <p className="mb-4 text-black w-4/5 text-xl">
                Transform your vision into reality with ease! Our user-friendly
                platform lets you personalize your apparel in just a few clicks.
                Enjoy the flexibility to:
              </p>
              <ul className="mb-3 text-black w-4/5 text-xl list-disc list-inside">
                <li>Experiment with multiple design options</li>
                <li>Craft your one-of-a-kind design with Drip Saint</li>
              </ul>
              <div className="h-2 mb-4"></div>
              <p className="text-black w-4/5 text-xl">
                When you're satisfied, simply checkout and complete your order.
                Our seamless personalization process makes it easy to bring your
                design to life and make it shine.
              </p>
            </div>
            <img
              src="/dripSaintAssets/LandingPage2.jpg"
              alt=""
              className="w-5/12 rounded-[20px] hidden md:block"
            />
          </div>
        )}
      </section>
      <section className="mt-28 pt-28">
        {isMobile ? (
          <div className="flex flex-col items-center mb-16 gap-8">
            <img
              src="/dripSaintAssets/LandingPage3.jpg"
              alt=""
              className="w-5/6 rounded-[20px]"
            />
            <div className="p-8 transparent rounded-tl-3xl min-w-[343px] shadow-2xl">
              <h1 className="mb-8 text-black font-bold text-3xl">
                Swift printing and delivery
              </h1>
              <p className="mb-4 text-black w-4/5 text-md">
                Experience the best in printing and delivery! After finalizing
                your design, our state-of-the-art printer ensures a flawless
                transfer of your design onto high-quality apparel. Our rigorous
                quality inspections guarantee a defect-free print every time.
                Your apparel is then expertly folded, professionally packaged,
                and swiftly shipped to your doorstep, ready to impress. Enjoy:
              </p>
              <ul className="mb-4 text-black w-4/5 text-md list-disc list-inside">
                <li>High-quality apparel that exceeds your expectations</li>
                <li>The best print machines for crisp and vibrant designs</li>
                <li>Fastest delivery to get your apparel to you quickly</li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-evenly items-center mb-16 gap-32">
            <div className="p-8 transparent rounded-tl-3xl min-w-[343px] md:w-1/2 shadow-2xl">
              <h1 className="mb-8 text-black font-bold text-4xl">
                Swift printing and delivery
              </h1>
              <p className="mb-4 text-black w-4/5 text-xl">
                Experience the best in printing and delivery! After finalizing
                your design, our state-of-the-art printer ensures a flawless
                transfer of your design onto high-quality apparel. Our rigorous
                quality inspections guarantee a defect-free print every time.
                Your apparel is then expertly folded, professionally packaged,
                and swiftly shipped to your doorstep, ready to impress. Enjoy:
              </p>
              <ul className="mb-4 text-black w-4/5 text-xl list-disc list-inside">
                <li>High-quality apparel that exceeds your expectations</li>
                <li>The best print machines for crisp and vibrant designs</li>
                <li>Fastest delivery to get your apparel to you quickly</li>
              </ul>
            </div>
            <img
              src="/dripSaintAssets/LandingPage3.jpg"
              alt=""
              className="w-5/12 rounded-[20px] hidden md:block"
            />
          </div>
        )}
      </section>
      <section className="lg:ml-0 pt-12">
        <div className="w-full sm:w-4/5 rounded-3xl mx-auto mt-22">
          <h1 className="text-center rounded-3xl text-4xl font-bold text-black p-4">
            Customer Reviews
          </h1>
          <div className="w-full flex flex-col justify-center items-center sm:flex-row gap-4 mb-4">
            <div className="bg-[#D2D2D2] rounded-3xl p-4 w-full sm:w-auto">
              <div className="w-full flex flex-row justify-end items-center mb-2">
                <img
                  src="/dripSaintAssets/boy.png"
                  alt=""
                  className="w-1/5 rounded-full"
                />
                <h2 className="ml-4 flex-1 text-xl text-black">Abdullah</h2>
              </div>
              <p className="text-black">
                I bought these 220gsm tshirt and I’m really happy with this. The
                material is thick and feels very smooth on the skin. It doesn’t
                shrink at all after washing. i am small size and i boufht thier
                Medium size, it is authentic baggy.
              </p>
            </div>
            <div className="bg-[#D2D2D2] rounded-3xl p-4 w-full sm:w-auto">
              <div className="w-full flex flex-row justify-end items-center mb-2">
                <img
                  src="/dripSaintAssets/girl.png"
                  alt=""
                  className="w-1/5 rounded-full"
                />
                <h2 className="ml-4 flex-1 text-xl text-black">Mariam</h2>
              </div>
              <p className="text-black">
                I saved my money to buy this shirt and I absolutely impressed
                with the print qualitu. The colors are vibrant and really good.
                It looks just as good as even after several washes, with no
                fading at all.
              </p>
            </div>
            <div className="bg-[#D2D2D2] rounded-3xl p-4 w-full sm:w-auto">
              <div className="w-full flex flex-row justify-end items-center mb-2">
                <img
                  src="/dripSaintAssets/man.png"
                  alt=""
                  className="w-1/5 rounded-full"
                />
                <h2 className="ml-4 flex-1 text-xl text-black">Ali</h2>
              </div>
              <p className="text-black">
                I love buying from Dripsaint. Their our collection sections are
                incredible, there’s something for everyone. I am die hard anime
                fan and i find alot alot og anime desigms and i love them all.
              </p>
            </div>
          </div>
          <div className="bg-[#D2D2D2] text-black rounded-3xl p-12 flex flex-col sm:flex-row justify-center items-center mb-6">
            <div className="mb-10 sm:w-1/2 h-full">
              <h1 className="text-3xl mb-6">Get in touch</h1>
              <p className="sm:w-3/4">
                Experience the future of fashion with this AI-designed t-shirt.
                Its abstract yet captivating design reflects the boundless
                creativity of artificial intelligence. Crafted with high-quality
                fabric and boasting a flawless fit, it's a statement piece that
                merges innovation and style seamlessly.
              </p>
            </div>
            <div className="sm:w-1/2 h-full p-4 mb-4">
              {error && <p className="text-red-500 mb-4">{error}</p>}

              <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                <input
                  type="text"
                  name="Firstname"
                  placeholder="First Name"
                  // value={firstName}
                  // onChange={(e) => setFirstName(e.target.value)}
                  className="input w-full max-w-xs bg-white text-black placeholder-gray-600 border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="input w-full max-w-xs bg-white text-black placeholder-gray-600 border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
              <input
                type="text"
                name="Lastname"
                placeholder="Email"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                className="input w-full bg-white text-black placeholder-gray-600 border border-gray-300 rounded p-2 mt-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <textarea
                name="Message"
                className="textarea bg-white text-black w-full placeholder-gray-600 border border-gray-300 rounded p-2 mt-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Message"
                // value={message}
                // onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <button
                type="submit"
                className="w-full mt-4 bg-[#D2D2D2] text-black py-2 px-4 rounded border border-black focus:outline-none focus:ring-2"
                onMouseEnter={(e) => {
                  e.target.style.color = ""; // Change text to black on hover
                  e.target.style.background = "#D2D2D2"; // Purple gradient on hover
                  e.target.style.boxShadow =
                    "0 12px 20px rgba(255, 255, 255, 0.5)"; // Enhanced shadow
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = ""; // Let the className handle text color
                  e.target.style.background = "#D2D2D2"; // Let the className handle background color
                  e.target.style.boxShadow = "";
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
