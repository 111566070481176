import axios from 'axios'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_CLIENT_BASE_URL
    : process.env.REACT_APP_LOCAL_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});


axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosClient;
