import React, { createContext, useState, useContext, useEffect } from 'react';
import { UserContext } from '../auth/UserContext';
import axiosClient from '../utils/axiosClient';

export const AddToCartContext = createContext();

export const AddToCartProvider = ({ children }) => {
  const cartData = JSON.parse(localStorage.getItem("cartState"))
  const user = useContext(UserContext)
  const [cartState, setCartState] = useState(cartData || {
    cart: [],
    designCart: [],
    customCart: []
  });
  
  const [cartOpen, setCartOpen] = useState(false)
  const [cartLoading, setCartLoading] = useState(false)
    const [cartCount, setCartCount] = useState(() => {
    const savedCount = localStorage.getItem('cartCount');
    return savedCount ? parseInt(savedCount, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem('cartCount', cartCount);    
    // getCartDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartCount, user]);

  useEffect(()=> {
    const cartData = localStorage.getItem("cartState")
    if(cartData)
      setCartState(JSON.parse(cartData))
  }, [])

  useEffect(()=> {
    console.log({cartState});
    
    localStorage.setItem('cartState', JSON.stringify(cartState));
  }, [cartState])

  // const getCartDetails = async () => {
  //   try {
  //     if(user){
  //       setCartLoading(true)
  //       const productCartResponse = await axiosClient.get(`/api/cart`, {
  //         withCredentials: true,
  //       });
  //       const designCartResponse = await axiosClient.get(`/api/design_cart`, {
  //         withCredentials: true,
  //       });
  
  //       const customCartResponse = await axiosClient.get(`/api/custom_cart`, {
  //         withCredentials: true,
  //       });
  //       setCartState({
  //         cart: productCartResponse.data.cart || [],
  //         designCart: designCartResponse.data.cart || [],
  //         customCart: customCartResponse.data.cart || [],
  //       });
  //       setCartLoading(false)
  //     }
  //   } catch (error) {
  //     setCartLoading(false)
  //   }
  // }
  

  const addToCart = () => {
    setCartCount((prevCount) => prevCount + 1);
  };

  const removeFromCart = () => {
    setCartCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  // New function to reset the cart count to 0
  const resetCart = () => {
    setCartCount(0);
  };

  return (
    <AddToCartContext.Provider value={{ cartCount, addToCart, removeFromCart, resetCart, cartLoading, cartState, setCartState, setCartOpen, cartOpen }}>
      {children}
    </AddToCartContext.Provider>
  );
};

export const useAddToCart = () => {
  return useContext(AddToCartContext);
};
