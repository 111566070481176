// AiGenerationsContext.js
import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AiGenerationsConfirmationPopup from "../Components/common/AiGenerationsConfirmationPopup";

export const AiGenerationsContext = createContext();

export const AiGenerationsProvider = ({ children }) => {
  const user = localStorage.getItem('token');
  const navigate = useNavigate();
  const [counter, setCounter] = useState(() => {
    const savedCounterData = JSON.parse(localStorage.getItem("aiGenerationsCounterData"));
    if (savedCounterData && new Date() - new Date(savedCounterData.date) < 14 * 24 * 60 * 60 * 1000) {
      return savedCounterData.counter;
    }
    return 0;
  });
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Save counter and date to localStorage whenever the counter changes
    localStorage.setItem("aiGenerationsCounterData", JSON.stringify({ counter, date: new Date() }));
  }, [counter]);
  const handleButtonClick = () => {
    if (user) {
      if (counter >= 6) {
        setShowPopup(true);
        return;
      }
    } else {
      if (counter >= 3) {
        setShowPopup(true);
        return;
      }
    }

    // Increment counter if within limits
    setCounter(counter + 1);
  };

  const handleLogin = () => {
    setShowPopup(false);
    navigate("/login");
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  return (
    <AiGenerationsContext.Provider value={{ counter, handleButtonClick, showPopup }}>
      {children}
      {showPopup && (
        <AiGenerationsConfirmationPopup
          message={`Free Attempts are ended. ${counter>=5?'You need to wait for 3 days to more AI generations.':'You must log in for more AI generations.'}!`}
          onLogin={handleLogin}
          onCancel={handleCancel}
        />
      )}
    </AiGenerationsContext.Provider>
  );
};
