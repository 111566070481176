import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Layout from "../Components/Layout";
import axiosClient from "../utils/axiosClient";
import { FaArrowLeft, FaArrowRight, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AddToCartContext, useAddToCart } from "../Context/AddToCartContext";
import Loader from "./Loader";
import HotPicks from "../Components/Hotpicks";

const CustomProductPage = () => {
  const { productKey } = useParams();
  const { cartState, setCartState, setCartOpen } = useContext(AddToCartContext);
  const { addToCart } = useAddToCart();
  const sizecharthoodiepath = "/dripSaintAssets/sizeguide.webp";
  const [productData, setProductData] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const [custom, setCustom] = useState("no");
  const [Hoddiefeature, setHoddieFeatures] = useState([
      "Premium Fabric: Made from a 300 GSM cotton-polyester blend, offering a perfect balance of softness, durability, and breathability.",
      "Oversized Fit: Relaxed silhouette for maximum comfort and a trendy, streetwear-inspired look.",
      "Kangaroo Pocket: Functional front pocket to keep your hands warm or store small essentials.",
      "Ribbed Finishes: Ribbed cuffs and hem ensure a snug fit while maintaining the oversized aesthetic.",
      "Unisex Design: Suitable for all genders, perfect for anyone seeking comfort with a fashionable edge."
    ]);
    const [isSizeCharthoddieModalOpen, setIsSizeCharthoodieModalOpen] =
      useState(false);
 const [discription, setdiscription] = useState("Experience the perfect blend of style and comfort with our Premium 100% Cotton Oversized T-Shirt. Crafted from high-quality, 220 GSM fabric, this tee offers exceptional durability and softness, ensuring you stay comfortable all day long.");
  const [discription1, setdiscription1] = useState("Crafted from high-quality, 220 GSM fabric, this tee offers exceptional durability and softness, ensuring you stay comfortable all day long. Designed with an oversized fit, it provides a relaxed and effortless look, making it ideal for everyday wear. Whether you’re lounging at home, running errands, or meeting friends, this versatile t-shirt is your go-to choice for a laid-back, fashionable appearance.");
  const [quantity, setQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSizeChartModalOpen, setIsSizeChartModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Added state for loading indicator
//const custom="no";
  const sizechartpath = "/dripSaintAssets/shirtsizeguide.webp";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(`/api/get_product_details/${productKey}`, {
          withCredentials: true,
        });
        setProductData(response.data.Product);
       // setProductData(response.data.type);
 if(productData.product_type==34){

  setdiscription("Elevate your comfort game with our 300 GSM Cotton-Polyester Blend Oversized Hoodie—the perfect fusion of style and coziness. Crafted from a premium 300 GSM fabric, this hoodie boasts a thick yet breathable feel, offering warmth without compromising on comfort.");
  setdiscription1("Designed with an oversized silhouette, it provides a relaxed, streetwear-inspired vibe, making it ideal for layering or wearing solo. The cotton-polyester blend ensures durability, wrinkle resistance, and a soft-touch finish that feels gentle against the skin. Features include a spacious kangaroo pocket to keep essentials within reach, ribbed cuffs and hem for a secure fit, and an adjustable drawstring hood to keep you covered in style. Whether you're lounging at home, hitting the streets, or traveling, this hoodie will become your go-to wardrobe essential.");
  
  

 }
        console.log('shhs',response.data.Product)
        setImages([response.data.Product.thumbnail, ...response.data.Images.map(img => img.media)]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product data:", error);
        setLoading(false);
      }
    };

    fetchData();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [productKey]);
  const handleAddToCart = async () => {
    // if (Cookies.get("jwt")) {
    setIsSubmitting(true);
    try {
     
      const body =
        {
            id: Math.floor(Math.random() * 999),
            design: "Product",
            quantity,
            product_name: productData.product_name,
            size: selectedSize,
          //  type:productData.pr
            design_image:images[0],
            image:images[0],

            shirt_style_image:images[0],

            product:{
            priceKey:productData.product_price,

            product_price:productData.product_price,
            discount_price:productData.discount_price,
            }
            //discountKey:productData.discount_price
           // design_image: images[0],
            //shirt_style_image: images[0]
          };

          console.log(body);
      // if (!body.size || !body.shirt_style) {
      //   alert("please select size and shirt style first!");
      //   return false;
      // }
      // const response = await axiosClient.post(url, body, {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   withCredentials: true,
      // });

      // if (response.status === 200) {
    
        setCartState({ ...cartState, cart: [...cartState.cart, body] })
      
      setIsSubmitting(false);
      setIsModalOpen(false);
      setCartOpen(true)
      // setIsSuccessModalOpen(true);
      addToCart();
      // } else {
      //   console.error("Error adding to cart:", response.statusText);
      //   setIsSubmitting(false);
      // }
    } catch (error) {
      // if (error.response && error.response.status === 401) {
      //   navigate("/login");
      // }
      console.error("Error adding to cart:", error);

      setIsSubmitting(false);
    }
    // } else {
    //   navigate("/login");
    // }
  };

//   const handleAddToCart = async () => {
//     if (!selectedSize) {
//       alert("Please select a size before adding to cart.");
//       return;
//     }

//     setIsSubmitting(true);
//     try {
//       const body = {
      
        
//         id: Math.floor(Math.random() * 999),
//         design: "product",
//         quantity,
//         shirt_style: productData.name,
//         size: selectedSize,
//         design_image: images[0],
//         shirt_style_image: images[0]// Add color selection if needed
//       };

//       const response = await axiosClient.post("/api/custom_cart/", body, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//         withCredentials: true,
//       });
// console.log(response)
//       if (response.status === 200) {
//         setCartState(prevState => ({
//           ...prevState,
//           designCart: [...prevState.designCart, {
//             ...body,
//             product: productData, // Use full product data in cart state
//           }]
//         }));
//         setCartOpen(true);
//         addToCart();
//         setIsModalOpen(false);
//       } else {
//         console.error("Error adding to cart:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error adding to cart:", error);
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantity(Math.max(1, newQuantity));
  };
  const handleIsSizeCharthoddieModalOpen = () => {
    setIsSizeCharthoodieModalOpen(true);
  };

  const handleIsSizeCharthoddieModalclose = () => {
    setIsSizeCharthoodieModalOpen(false);
  };
  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  if (loading) {
    return <Loader text1="Loading product details..." text2="Please wait" />;
  }

  return (
    <Layout background="bg-[#FEF2DF]">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 py-20">
        <div className="flex flex-col lg:flex-row lg:gap-4">
          {/* Product Images */}
          <div className="lg:w-2/3">
            <div className="relative">
              <img
                src={`https://api.dripsaint.com${images[currentImageIndex]}`}
                alt={productData.product_name}
                className="w-full h-[460px] object-contain rounded-lg"
                onContextMenu={(e) => e.preventDefault()}
              />
              <button
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-[#D2D2D2] text-black py-2 px-4 rounded-full font-bold"
                onClick={() => setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1))}
              >
                <FaArrowLeft />
              </button>
              <button
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-[#D2D2D2] text-black py-2 px-4 rounded-full font-bold"
                onClick={() => setCurrentImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1))}
              >
                <FaArrowRight />
              </button>
            </div>
            <div className="flex gap-4 mt-4 overflow-x-auto">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={`https://api.dripsaint.com${image}`}
                  alt={`${productData.product_name} ${index + 1}`}
                  className={`w-24 h-24 object-cover rounded-lg cursor-pointer ${
                    currentImageIndex === index ? "border-2 border-purple-500" : ""
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                  onContextMenu={(e) => e.preventDefault()}
                />
              ))}
            </div>
          </div>

          {/* Product Details */}
          <div className="lg:w-1/3 mt-8 lg:mt-0">
            <h2 className="text-3xl font-bold text-black mb-4">{productData.product_name}</h2>
            <p className="text-xl font-bold text-black mb-4">
              Price: Rs <span className="text-2xl">{productData.discount_price || productData.product_price}</span>
              {productData.discount_price && (
                <span className="text-lg line-through ml-2 text-gray-500">
                  Rs {productData.product_price}
                </span>
              )}
            </p>
            <p className="text-black mb-4">{productData.product_detail}</p>
           {productData.product_type!=34?( <p className="text-black mb-4">
              Looking for your size? Check{" "}
              <span
                className="text-purple-800 font-bold underline cursor-pointer"
                onClick={() => setIsSizeChartModalOpen(true)}
              >
                Size Chart
              </span>
            </p>):(
            <p className="text-md text-black">
                      Looking for your Hoodie size? Check{" "}
                      <span
                        className="text-black dark:text-black font-bold underline cursor-pointer hover:text-[#D2D2D2]"
                        onClick={handleIsSizeCharthoddieModalOpen}
                      >
                        Size Guide
                      </span>
                    </p>
            )}
            {/* Size Selection */}
            <div className="mb-4">
              <h3 className="text-lg font-bold text-black mb-2">Select Size:</h3>
              <div className="flex gap-2">
                {["S", "M", "L", "XL"].map((size) => (
                  <button
                    key={size}
                    className={`px-4 py-2 rounded-full ${
                      selectedSize === size
                        ? "bg-purple-500 text-white"
                        : "bg-gray-200 text-black"
                    }`}
                    onClick={() => handleSizeChange(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

            {/* Quantity Selection */}
            <div className="mb-4">
              <h3 className="text-lg font-bold text-black mb-2">Quantity:</h3>
              <div className="flex items-center gap-2">
                <button
                  className="px-3 py-1 bg-gray-200 rounded-full"
                  onClick={() => handleQuantityChange(quantity - 1)}
                >
                  -
                </button>
                <span className="text-black">{quantity}</span>
                <button
                  className="px-3 py-1 bg-gray-200 rounded-full"
                  onClick={() => handleQuantityChange(quantity + 1)}
                >
                  +
                </button>
              </div>
            </div>

            {/* Add to Cart Button */}
            <button
              className="bg-purple-500 text-white py-2 px-4 rounded-full font-bold w-full"
              onClick={handleAddToCart}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Adding to Cart..." : "Add to Cart"}
            </button>
          </div>
        </div>

        {/* Product Description (Accordion for mobile, full text for desktop) */}
         {isMobile && (
                       <div className="mt-8 text-black">
                         {/* Model Information (Static, no toggle) */}
                         {/* <div className="mb-8">
                           <p className="text-base font-open-sans">
                             The Model is 5’7” wearing small (S) size
                           </p>
                         </div> */}
       
                         {/* Accordion Section for Product Description */}
       
                         
                         <div className="mt-4">
                           <button
                             className="w-full text-left flex items-center justify-between py-2 font-bold font-open-sans"
                             onClick={() => toggleSection("productDescription")}
                           >
                             <span>Product Description</span>
                             {activeSection === "productDescription" ? (
                               <FaChevronUp />
                             ) : (
                               <FaChevronDown />
                             )}
                           </button>
                           {activeSection === "productDescription" && (
                             <div className="pt-2 text-base font-open-sans">
                               <p>
                                 {discription}
                               </p>
                               <p className="pt-4">
                                {discription1}
                               </p>
                             </div>
                           )}
                         </div>
       
                         {/* Accordion Section for Key Features */}
       
                         
                         <div className="mt-4">
         <button
           className="w-full text-left flex items-center justify-between py-2 font-bold font-open-sans"
           onClick={() => toggleSection("keyFeatures")}
         >
           <span>Key Features</span>
           {activeSection === "keyFeatures" ? <FaChevronUp /> : <FaChevronDown />}
         </button>
         {activeSection === "keyFeatures" && (
           productData.product_type == 34 ? (
             <div className="pt-2 text-base font-open-sans">
               <ul className="list-disc pl-5">
                 {Hoddiefeature.map((x, index) => (
                   <li className="pt-2" key={index}>
                     {x}
                   </li>
                 ))}
               </ul>
             </div>
           ) : (
             <div className="pt-2 text-base font-open-sans">
               <ul className="list-disc pl-5">
                 <li className="pt-2">
                   Material: 100% Cotton for ultimate softness and breathability.
                 </li>
                 <li className="pt-2">
                   Fabric Weight: 220 GSM for superior durability and comfort.
                 </li>
                 <li className="pt-2">
                   Fit: Oversized for a relaxed and trendy look.
                 </li>
                 <li className="pt-2">
                   Usage: Perfect for everyday wear, providing comfort and style.
                 </li>
               </ul>
             </div>
           )
         )}
       </div>
       
       
                         {/* Accordion Section for Care Instructions */}
                         <div className="mt-4">
                           <button
                             className="w-full text-left flex items-center justify-between py-2 font-bold font-open-sans"
                             onClick={() => toggleSection("careInstructions")}
                           >
                             <span>Care Instructions</span>
                             {activeSection === "careInstructions" ? (
                               <FaChevronUp />
                             ) : (
                               <FaChevronDown />
                             )}
                           </button>
                           {activeSection === "careInstructions" && (
                             <div className="pt-2 text-base font-open-sans">
                               <ul className="list-disc pl-5">
                                 <li className="pt-2">Wash it inside out.</li>
                                 <li className="pt-2">Gentle Machine wash.</li>
                                 <li className="pt-2">Do not bleach.</li>
                                 <li className="pt-2">Iron inside out.</li>
                                 <li className="pt-2">
                                   Do not iron directly on prints.
                                 </li>
                                 <li className="pt-2">Do not dry clean.</li>
                               </ul>
                             </div>
                           )}
                         </div>
                       </div>
                     )}
                     {!isMobile && (
                       <div className="mt-8">
                         {/* <div className="text-black mb-8">
                           <p className="text-base font-open-sans">
                             The Model is 5’7” wearing small (S) size
                           </p>
                         </div> */}
                         <div className="my-8"></div>
                         {/* Model Information */}
                         <div className="text-black">
                           <span className="text-2xl font-bold font-open-sans">
                             Product Description:
                           </span>
                           <p className="text-base pt-2 font-open-sans">
                           {discription}
                           </p>
                           <p className="text-base pt-4 font-open-sans">
                             {discription1}
                           </p>
                           <div className="my-8"></div>
                           {/* Key Features */}
                           <h2 className="text-2xl font-bold pt-4 font-open-sans">
                             Key Features:
                           </h2>
       
                           {productData.product_type == 34 ? (
         <div className="text-base pt-2 font-semibold">
           <ul className="list-disc pl-5">
             {/* Replace the following placeholder with hoodie-specific features */}
             {Hoddiefeature.map((feature, index) => (
               <li key={index} className="pt-2">
                 {feature}
               </li>
             ))}
           </ul>
         </div>
       ) : (
         <ul className="text-base pt-2 font-semibold list-disc pl-5">
           <li className="pt-2">
             Material: 100% Cotton for ultimate softness and breathability.
           </li>
           <li className="pt-2">
             Fabric Weight: 220 GSM for superior durability and comfort.
           </li>
           <li className="pt-2">
             Fit: Oversized for a relaxed and trendy look.
           </li>
           <li className="pt-2">
             Usage: Perfect for everyday wear, providing comfort and style.
           </li>
         </ul>
       )}
       
                         </div>
                         {/* <div className="text-black mb-8">
           <p className="text-base font-open-sans">
             The Model is 5’7” wearing small (S) size
           </p>
         </div> */}
       
                         <div className="my-8"></div>
       
                         {/* Care Instructions */}
                         <div className="text-black">
                           <span className="text-2xl font-bold font-open-sans pt-2">
                             Care Instructions:
                           </span>
                           <ul className="pt-4 text-base font-semibold list-disc pl-5">
                             <li className="pt-2">Wash it inside out.</li>
                             <li className="pt-2">Gentle Machine wash.</li>
                             <li className="pt-2">Do not bleach.</li>
                             <li className="pt-2">Iron inside out.</li>
                             <li className="pt-2">Do not iron directly on prints.</li>
                             <li className="pt-2">Do not dry clean.</li>
                           </ul>
                         </div>
       
                         {/* Gap Between Sections */}
                         {/* Adjust the margin as needed for desired gap */}
       
                         {/* Product Description */}
                       </div>
                     )}

        {/* Best Sellers Section */}
        <HotPicks title="Best Sellers" />

        {/* Free Shipping Image */}
        <div className="flex justify-center mt-8">
          <img
            src="/dripSaintAssets/Free Shipping.png"
            alt="Free Shipping"
            className="w-full max-w-lg"
          />
        </div>
      </div>

      {/* Size Chart Modal */}
      {isSizeChartModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg max-w-3xl w-full">
            <button
              className="float-right text-2xl font-bold"
              onClick={() => setIsSizeChartModalOpen(false)}
            >
              &times;
            </button>
            <img src={sizechartpath} alt="Size Chart" className="w-full" />
          </div>
        </div>
      )}
          {isSizeCharthoddieModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 p-0 rounded-lg shadow-lg flex justify-center flex-col gap-2 w-full max-w-3xl mx-4 sm:mx-0 relative">
            {/* Close Button */}
            <button
              className="bg-black dark:bg-black text-white py-2 px-4 rounded-full font-bold absolute top-2 right-2"
              onClick={handleIsSizeCharthoddieModalclose}
            >
              x
            </button>

            {/* Size Chart Image */}
            <div className="flex justify-center p-0">
              <img
                src={sizecharthoodiepath}
                alt="Size Chart"
                className="w-full object-cover m-0"
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CustomProductPage;

